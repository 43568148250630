<template>
    <v-container fluid>
        <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <v-form ref="dataForm" @submit.prevent="save">
                    <v-card class="mb-8">
                        <v-card-title tag="h1" class="mb-4">Main Banner</v-card-title>
                        <v-card-text>
                            <ImageUploader 
                                singleImage 
                                fileInputKey="upload_image_banner"
                                :imgList.sync="formData.banner_image" 
                                acceptFormat="image"
                                :dispatchUpdateOnChange="dispatchUpdateOnChange"
                            ></ImageUploader>
                        </v-card-text>
                    </v-card>
                    <v-card class="mb-8" v-for="item in types" :key="item.value">
                        <v-card-title tag="h1" class="mb-4">{{ item.text }}</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <ImageUploader 
                                        singleImage 
                                        :fileInputKey="`upload_image_${item.value}`"
                                        :imgList.sync="formData[item.value].image" 
                                        acceptFormat="image"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                    ></ImageUploader>
                                </v-col>
                                <v-col cols="12">
                                    <FormTextarea
                                        label="Introduction Content"
                                        :fieldValue.sync="formData[item.value].introduction"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                    ></FormTextarea>
                                </v-col>
                                <v-col cols="12">
                                    <h3>Technology overview</h3>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormTextarea
                                        label="Min part size"
                                        :fieldValue.sync="formData[item.value].min_part_size"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        :rows="2"
                                    ></FormTextarea>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormTextarea
                                        label="Max part size"
                                        :fieldValue.sync="formData[item.value].max_part_size"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        :rows="2"
                                    ></FormTextarea>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormTextarea
                                        label="Layer height"
                                        :fieldValue.sync="formData[item.value].layer_height"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        :rows="2"
                                    ></FormTextarea>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormTextarea
                                        label="Tolerance"
                                        :fieldValue.sync="formData[item.value].tolerance"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        :rows="2"
                                    ></FormTextarea>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <FormTextarea
                                        label="Min wall thickness"
                                        :fieldValue.sync="formData[item.value].min_wall_thickness"
                                        :dispatchUpdateOnChange="dispatchUpdateOnChange"
                                        :rows="2"
                                    ></FormTextarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>


                    <v-btn depressed color="primary accent-4" class="white--text" type="submit"
                        :loading="$store.getters.isLoading"> Save </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import FormInput from '@/components/form/FormInput.vue';
import FormSelect from '@/components/form/FormSelect.vue';
import ImageUploader from '@/components/ImageUploader.vue';
import FormTextarea from '@/components/form/FormTextarea.vue';

export default {
    name: 'PrintingService',
    components: {
        FormInput,
        FormSelect,
        ImageUploader,
        FormTextarea
    },
    computed: {
        dispatchUpdateOnChange() {
            return true;
        },
    },
    data: () => ({
        formData: {
            banner_image: [],
            mjf: {
                image: [],
                introduction: '',
                min_part_size: '',
                max_part_size: '',
                layer_height: '',
                tolerance: '',
                min_wall_thickness: ''
            },
            sls: {
                image: [],
                introduction: '',
                min_part_size: '',
                max_part_size: '',
                layer_height: '',
                tolerance: '',
                min_wall_thickness: ''
            },
            sla: {
                image: [],
                introduction: '',
                min_part_size: '',
                max_part_size: '',
                layer_height: '',
                tolerance: '',
                min_wall_thickness: ''
            },
            fdm: {
                image: [],
                introduction: '',
                min_part_size: '',
                max_part_size: '',
                layer_height: '',
                tolerance: '',
                min_wall_thickness: ''
            },
        },


        // --- static data
        types: [
            { text: 'Multi Jet Fusion (MJF)', value: 'mjf' },
            { text: 'Selective Laser Sintering (SLS)', value: 'sls' },
            { text: 'Stereolithography (SLA)', value: 'sla' },
            { text: 'Fused Deposition model (FDM)', value: 'fdm' },
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getServiceData() {
            try {
                const data = await this.$Fetcher.GetServiceData();
                if (this.$validate.DataValid(data.banner_image)) {
                    this.formData.banner_image.push(data.banner_image);
                }

                ['mjf', 'sls', 'sla', 'fdm'].forEach(key => {
                    if (this.$validate.DataValid(data[`${key}_data`])) {
                        if (this.$validate.DataValid(data[`${key}_data`].image)) {
                            this.formData[key].image.push(data[`${key}_data`].image)
                        }

                        this.$set(this.formData[key], 'introduction', data[`${key}_data`].introduction);
                        this.$set(this.formData[key], 'min_part_size', data[`${key}_data`].min_part_size);
                        this.$set(this.formData[key], 'max_part_size', data[`${key}_data`].max_part_size);
                        this.$set(this.formData[key], 'layer_height', data[`${key}_data`].layer_height);
                        this.$set(this.formData[key], 'tolerance', data[`${key}_data`].tolerance);
                        this.$set(this.formData[key], 'min_wall_thickness', data[`${key}_data`].min_wall_thickness);
                    }
                })

           
               
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.$store.dispatch('toggleLoadingPage', false);
            }
        },


        async save() {
            if (this.$store.getters.isLoading) {
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'processing',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            this.$store.dispatch('setLoading', true);
            const valid = await this.$refs['dataForm'].validate();
            if (!valid) {
                this.$store.dispatch('setLoading', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'required',
                    type: 'error',
                    refresh: false,
                    redirect: ''
                });
                return;
            }

            let payload = {
                banner_image: this.$validate.DataValid(this.formData.banner_image) ? this.formData.banner_image[0] : '',
            };

            ['mjf', 'sls', 'sla', 'fdm'].forEach(key => {
                payload[`${key}_data`] = {
                    image: this.$validate.DataValid(this.formData[key].image) ? this.formData[key].image[0] : '',
                    introduction: this.formData[key].introduction,
                    min_part_size: this.formData[key].min_part_size,
                    max_part_size: this.formData[key].max_part_size,
                    layer_height: this.formData[key].layer_height,
                    tolerance: this.formData[key].tolerance,
                    min_wall_thickness: this.formData[key].min_wall_thickness,
                }
            })

            try {
                await this.$Fetcher.SetServiceData(payload);
                this.$store.dispatch('setDataIsUpdated', false);
                this.$store.dispatch('toggleAlertMessage', {
                    show: true,
                    message: 'Saved Successfully',
                    type: 'success',
                    refresh: true,
                    redirect: ''
                });

            } catch (err) {
                this.setDialogMessage({
                    title: "Saved Fail",
                    message: err,
                    isError: true,
                    returnLink: null,
                });
                this.setShowDialog(true);
            } finally {
                this.$store.dispatch('setLoading', false);
            }
        },
    },
    async created() {
        this.$store.dispatch('toggleLoadingPage', true);
        this.getServiceData();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  